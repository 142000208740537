import React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"
import Colors from "../constants/Colors"
import Links from "../constants/Links"
import Announcement from "react-announcement"
import logo from "../../static/square-logo.png"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1 style={headerStyles}>
          <Link style={linkStyles} to={`/`}>
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Roboto, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: Colors.white,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div style={layoutStyles}>
        <header>{header}</header>
        <main>{children}</main>
        <footer style={subtitle}>
          © {new Date().getFullYear()}, Bygget af
          {` `}
          <a style={subtitle} href="https://www.firma-navn.dk">
            Firma-Navn
          </a>
        </footer>
        <Announcement
          title="Rabatkode: 3GRATIS"
          subtitle="Klik her og brug denne rabatkode til 3 måneders gratis hosting hos Simply."
          imageSource={logo}
          link={Links.affiliateTrackingLink}
          closeIconSize={25}
        />
      </div>
    )
  }
}

const layoutStyles = {
  backgroundColor: Colors.themeColor,
  marginLeft: `auto`,
  marginRight: `auto`,
  maxWidth: rhythm(24),
  padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
}

const headerStyles = {
  ...scale(1.5),
  marginBottom: rhythm(1.5),
  marginTop: 0,
}

const linkStyles = {
  boxShadow: `none`,
  textDecoration: `none`,
  color: `inherit`,
}

const subtitle = {
  color: Colors.white,
  opacity: 0.8,
}

export default Layout
