const tintColor = '#00AF87';
const themeColor = '#19367C';
const lightThemeColor = '#EEF0F5';

export default {
  tintColor,
  themeColor,
  lightThemeColor,

  // Header
  headerBackground: themeColor,
  noticeBackground: tintColor,
  noticeText: '#fff',
  
  // Pure colors
  white: '#fff',
  black: '#000',
  green: '#46A597',
  almostBlack: '#292929',
  grey: '#868c91',
  lightGrey: '#DBDBD9',

  // Various elements
  borderColor: '#e8e8e8',
  shadow: '#3e396b',
  introBackground: '#FFF',
};